<template>
    <div v-loading="loading">
        <section>
            <article class="mt-5">
            <div class="container mt-5">
                <div class="row w-100 mb-4">
                  <div class="col-12 pl-3 px-0 mb-0 text-left">
                      <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/ovalspace/os-admin/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/ovalspace/os-admin/categories' }">Categories</el-breadcrumb-item>
                      <el-breadcrumb-item>Rooms</el-breadcrumb-item>
                      </el-breadcrumb>
                  </div>
                </div>
                <h1 style="font-size: 1.5em; font-weight: 600;">Rooms:</h1>
                <div
                style="display: flex; align-items:center; justify-content: space-between;"
                class="search_add_section"
                >
                  <div style="width: 50%;">
                      <input
                      type="text"
                      v-model.trim="filters[0].value"
                      placeholder="Search by Room Name"
                      class="search_by_input form-control"
                      spellcheck="off"
                      />
                  </div>
                  <div
                  :disabled="$store.state.userRoles !== 'SUPER_ADMIN'">
                      <AddRoomDialog
                          @re-fresh="getRooms" 
                      />
                  </div>
                  
                  <div v-if="qrCodeDataUrl" id="qrcode">
                    <img :src="qrCodeDataUrl" alt="QR Code" />
                  </div>
                </div>
                <hr />
    
                <div
                v-loading="loading || is_changing"
                class="table-responsive mb-5"
                >
                <data-tables
                    :data="rooms"
                    :table-props="tableProps"
                    :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                    type="expand"
                    :filters="filters"
                >
    
                    <div slot="empty">
                    <ScaleOut
                        v-if="isLoading && !loadingError"
                        class="mt-3"
                        :background="'#164B70'"
                    />
                    <div
                        @click="getRooms"
                        v-else-if="!isLoading && loadingError"
                        style="cursor: pointer"
                    >
                        Unable to Load Rooms Now. Please click here to retry
                    </div>
                    <div v-if="!isLoading && !loadingError">No Rooms</div>
                    </div>
    
                    <el-table-column
                    label="#"
                    :show-overflow-tooltip="false"
                    label-class-name="text-center"
                    class-name="text-center"
                    width="80px"
                    >
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                    </el-table-column>
    
                    <el-table-column
                    label="Room Info"
                    :show-overflow-tooltip="false"
                    label-class-name="text-left"
                    class-name="text-left"
                    >
                    <template slot-scope="scope">
                      <div class="d-flex align-items-center">
                        <div v-if="scope.row.roomImage" class="pr-2">
                          <img
                            :src="scope.row.roomImage"
                            style="width: 30px; height: 30px; border-radius: 50%; object-fit: cover;"
                          >
                        </div>
                        <div>
                          <span> {{ scope.row.roomNumber }} </span><br />
                          <!-- <small>Max Occupancy: {{ scope.row.maxOccupancy }} </small><br />
                          <small>Price Per Night: {{ scope.row.pricePerNight }} </small><br /> -->
                          <small>Add On: {{ formattedDate(scope.row.addedDate) }} </small>
                        </div>
                      </div>
                    </template>
                    </el-table-column>
                    
    
                    <el-table-column
                    label="Status"
                    label-class-name="text-center"
                    class-name="text-center"
                    width="100px"
                    >
                    <template slot-scope="scope">
                        <el-row style="display: flex;">
                        <el-tooltip
                            :content="scope.row.isOccupied ? 'Active' : 'Deactivated'"
                            placement="top"
                        >
                          {{ scope.row.isOccupied }}
                            <el-switch
                            v-model="scope.row.isOccupied"
                            @change="switchStatus(scope.row.isOccupied, scope.row.roomID)"
                            active-color="#13ce66"
                            inactive-color=""
                            >
                            </el-switch>
                        </el-tooltip>
                        </el-row>
                    </template>
                    </el-table-column>
    
                    
                    <el-table-column
                    label="Rooms"
                    :show-overflow-tooltip="false"
                    label-class-name="text-center"
                    class-name="text-center"
                    >
                    <template slot-scope="scope">
                        <el-button
                          type="primary"
                          @click="$router.push({path: `/ovalspace/os-admin/event-calendar/${$route.params.categoryID}/${scope.row.roomID}`})"
                          icon="el-icon-date"
                          round
                        >Calendar</el-button>
                    </template>
                    </el-table-column>


                    <el-table-column
                      label="Review"
                      :show-overflow-tooltip="false"
                      label-class-name="text-center"
                      class-name="text-center"
                    >
                      <template slot-scope="scope">
                          <el-button
                              type="warning"
                              @click="generateQRCodeAndDownloadPDF(scope.row.roomID, scope.row.roomNumber)"
                              round
                              >Generate QR Code</el-button>
                      </template>
                    </el-table-column>
    
                    <el-table-column
                    label="Actions"
                    :show-overflow-tooltip="false"
                    label-class-name="text-center"
                    class-name="text-center"
                    >
                    <template slot-scope="scope">
                        <el-row style="display: flex; align-items:center; justify-content:center;">
                          <el-tooltip
                          content="Edit"
                          placement="top"
                        >
                          <el-button
                            @click="showEditRoomData(scope.row)"
                            type="warning"
                            :disabled="$store.state.userRoles !== 'SUPER_ADMIN'"
                            icon="el-icon-edit"
                            circle
                          ></el-button>
                        </el-tooltip>

                        <el-tooltip
                            content="Delete"
                            placement="top"
                        >
                            <el-button
                            type="danger"
                            :disabled="$store.state.userRoles !== 'SUPER_ADMIN'"
                            @click="deleteRoom(scope.row.roomID)"
                            icon="el-icon-delete"
                            circle
                            ></el-button>
                        </el-tooltip>
                        </el-row>
                    </template>
                    </el-table-column>
    
                </data-tables>
                </div>
    
            </div>
            </article>
        </section>

        

    <EditRoomDialog
      :showRoomEditDialog="showRoomEditDialog"
      :roomData="roomData"
      @closeRoomEditDialog="closeRoomEditDialog"
      @re-fresh="getRooms"
    />
    </div>
  </template>
  
  <script>
  import QRCode from 'qrcode';
  import jsPDF from 'jspdf';
  import ScaleOut from "@/components/backoffice/scale-out-component.vue";
  import AddRoomDialog from "../../components/backoffice/dialogs/add-room-dialog.vue";
  import EditRoomDialog from "../../components/backoffice/dialogs/edit-room-dialog";
  // import ColorsDialog from "../../components/backoffice/dialogs/colors-dialog.vue";
  // import AssignProductProductGroups from "../../components/backoffice/dialogs/assign-product-product-groups.vue";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut,
      AddRoomDialog,
      EditRoomDialog
      // ColorsDialog,
      // AssignProductProductGroups,
    },
  
    data() {
      return {
        loading: false,
        isLoading: false,
        is_changing: false,
        showUserEditDialog: false,
        rooms: [],
        user_data: {},
        showRoomEditDialog: false,
        roomData: {},
        qrCodeDataUrl: '',
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
        filters: [
          {
            prop: "roomNumber",
            value: "",
          },
        ],
        loadingError: false,
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getRooms();
    },
  
    methods: {
      
      showEditRoomData(roomData) {
        this.showRoomEditDialog = true;
        this.roomData = roomData;
      },

      closeRoomEditDialog() {
        this.showRoomEditDialog = false;
      },
  
      async getRooms() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`rooms/get-rooms/${this.$route.params.categoryID}`);
          if (request.data.success && request.data.message === "ROOMS_FETCHED_SUCCESSFULLY") {
            this.rooms = request.data.rooms;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch Rooms now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async switchStatus(activation_status, productId) {
        try {
          this.is_changing = true;
          let request = await this.$http.patch(
            `rooms/change_isOccupied_status/${productId}`, {
              isOccupied: activation_status?'yes':'no',
            }
          );
          if (
            request.data.success &&
            request.data.message == "ROOM_UPDATED_SUCCESSFULLY"
          ) {
            this.getRooms();
            this.showSuccessMessage("Success", );
                return this.$notify({
                  title: "Success",
                  message: "Room status changed",
                  type: "success",
                });
          } else {
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            this.is_changing = false;
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to change staff status",
            "An unexpected error occurred, please try again"
          );
        } finally {
          this.is_changing = false;
        }
      },
  
      async deleteRoom(roomID) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Room. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`rooms/${roomID}`);
              if (
                request.data.success &&
                request.data.message === "ROOM_DELETED_SUCCESSFULLY"
              ) {
                this.getRooms();
                this.$notify({
                  title: "Success",
                  message: "Room deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete the Room, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },


      generateQRCode(roomID,roomNumber) {
        // Construct the detail page URL
        const detailPageUrl = `${window.location.origin}/guest-reviews/${roomID}/${roomNumber}`;

        // Generate the QR code
        QRCode.toDataURL(detailPageUrl)
          .then((url) => {
            this.qrCodeDataUrl = url;
          })
          .catch((err) => {
            console.error(err);
          });
      },

      generateQRCodeAndDownloadPDF(roomID, roomNumber) {
        // Construct the detail page URL
        const detailPageUrl = `${window.location.origin}/guest-reviews/${roomID}/${roomNumber}`;

        // Generate the QR code
        QRCode.toDataURL(detailPageUrl)
          .then((url) => {
            // Create a new PDF document
            const doc = new jsPDF();

            // Page dimensions
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();

            // Header section
            const headerTitle = "Happy with our service?";
            doc.setFontSize(24);
            doc.setFont('helvetica', 'bold');
            const headerTitleWidth = doc.getTextWidth(headerTitle);
            doc.text(headerTitle, (pageWidth - headerTitleWidth) / 2, 30);

            // Paragraph below the header
            const paragraph = `Help spread the word. Review ${roomNumber} Cottage.`;
            doc.setFontSize(16);
            doc.setFont('helvetica', 'normal');
            const paragraphWidth = doc.getTextWidth(paragraph);
            doc.text(paragraph, (pageWidth - paragraphWidth) / 2, 45);

            // QR code section
            const qrCodeSize = 80; // Size of the QR code
            const qrCodeX = (pageWidth - qrCodeSize) / 2;
            const qrCodeY = (pageHeight - qrCodeSize) / 2;
            doc.addImage(url, 'PNG', qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);

            // Footer section with business name
            const footerTitle = "Acacia Wilderness Mweya";
            doc.setFontSize(20);
            doc.setFont('helvetica', 'bold');
            const footerTitleWidth = doc.getTextWidth(footerTitle);
            doc.text(footerTitle, (pageWidth - footerTitleWidth) / 2, pageHeight - 30);

            // Footer paragraph
            const footerText = "Scan QR Code to Review Our Cottage";
            doc.setFontSize(14);
            doc.setFont('helvetica', 'normal');
            const footerTextWidth = doc.getTextWidth(footerText);
            doc.text(footerText, (pageWidth - footerTextWidth) / 2, pageHeight - 15);

            // Save the PDF with a filename
            doc.save(`Review_${roomNumber.replace(/\s+/g, '_')}.pdf`);
          })
          .catch((err) => {
            console.error("Error generating QR code:", err);
          });
      },


    },
  };
  </script>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>